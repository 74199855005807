import WeblinkHeader from "../components/WebLinkHeader"
import { useEffect, useState } from "react"
import { useLogto } from '@logto/react';
const { domain } = require('../config/generalConfig.json')

function UserManage() {
    var [visb, setVisb] = useState([true, false, false, false, false, false, false])
    const { signIn, getAccessToken, isAuthenticated, isLoading } = useLogto()
    const [accessToken, setAccessToken] = useState(null)
    const [existingUserList, setExistingUserList] = useState(null)
    const [usrLogtoID, setUsrLogtoID] = useState(null)
    const [existingAuthList, setExistingAuthList] = useState(null)
    const [existingPermList, setExistingPermList] = useState(null)
    const [managingAuthList, setManagingAuthList] = useState(1)
    const [delAuthList, setDelAuthList] = useState(["1", "1"])
    const [newAuthList, setNewAuthList] = useState({ "name": "", "permissions": ["1"] })
    const [selectLogUser, setSelectLogUser] = useState(null)
    const [userLogs, setUserLogs] = useState(null)
    const [changingUser, setChangingUser] = useState(null)
    const [changingAuth, setChangingAuth] = useState(null)
    const [addUserInfo, setAddUserInfo] = useState({ "name": "", "company": "", "department": "", "email": "", "password": "", "authlist": "1" })

    useEffect(() => {
        async function getAPIToken() {
            if (!isLoading && isAuthenticated && !accessToken) {
                const actk = await getAccessToken('https://api.cendori.entr.netfrc.com/')
                setAccessToken(actk)
            }
            console.log(accessToken)
        }

        getAPIToken()
        if (accessToken && !existingUserList && (visb[1] || visb[2] || visb[6])) fetchUserList()
        if (accessToken && !existingAuthList && (visb[0] || visb[1] || visb[4] || visb[5])) fetchAuthList()
        if (accessToken && !existingPermList && (visb[3] || visb[4])) fetchPermList()
        if (existingUserList) {
            setSelectLogUser(existingUserList[0].logtoid)
            setChangingUser(existingAuthList[0].logtoid)
        }
        if (existingAuthList) {
            setChangingAuth(existingAuthList[0].id)
        }

        // Send Log Informations 
        if (accessToken && visb[0]) sendLogInfo("유저 추가 페이지")
        else if (accessToken && visb[1]) sendLogInfo("유저 권한 관리 페이지")
        else if (accessToken && visb[2]) sendLogInfo("유저 삭제 페이지")
        else if (accessToken && visb[3]) sendLogInfo("권한 리스트 추가 페이지")
        else if (accessToken && visb[4]) sendLogInfo("권한 리스트 관리 페이지")
        else if (accessToken && visb[5]) sendLogInfo("권한 리스트 삭제 페이지")
        else if (accessToken && visb[6]) sendLogInfo("활동 로그 페이지")
    }, [visb, existingUserList, isAuthenticated, isLoading])

    async function sendLogInfo(text) {
        if (accessToken) {
            const formData = new FormData()
            formData.append("api", text)
            fetch('https://api.cendori.entr.netfrc.com/logs/view', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                body: formData
            }).then((response) => response.json())
                .then((data) => {
                    console.log(data)
                })
        }
    }

    async function fetchUserList() {
        fetch('https://api.cendori.entr.netfrc.com/user/list', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((response) => response.json())
            .then((data) => {
                if (data.error) alert(data.error)
                else {
                    if (data.authorized === false) {
                        alert("접근 권한 없음")
                        return
                    }
                    else {
                        setExistingUserList(data.data)
                        console.log(data.data)
                    }
                }
            })
    }

    async function fetchAuthList() {
        fetch('https://api.cendori.entr.netfrc.com/perms/authlist', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((response) => response.json())
            .then((data) => {
                if (data.error) alert(data.error)
                else if (data.authorized === false) {
                    alert("접근 권한 없음")
                    return
                }
                else {
                    setExistingAuthList(data.data)
                    console.log(data.data)
                }
            })
    }

    async function fetchPermList() {
        fetch('https://api.cendori.entr.netfrc.com/perms/permlist', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((response) => response.json())
            .then((data) => {
                if (data.error) alert(data.error)
                else {
                    if (data.authorized === false) {
                        alert("접근 권한 없음")
                        return
                    }
                    else {
                        setExistingPermList(data.data)
                        console.log(data.data)
                    }
                }
            })
    }

    async function deleteUser(e) {
        e.preventDefault()
        if (!accessToken) {
            alert("API 토큰 없음")
            return
        }
        else {
            const formData = new FormData()
            formData.append("logtoid", usrLogtoID)

            fetch('https://api.cendori.entr.netfrc.com/user/delete', {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                body: formData
            }).then((response) => response.json())
                .then((data) => {
                    if (data.error) alert(data.error)
                    else {
                        if (data.authorized === false) {
                            alert("접근 권한 없음")
                            return
                        }
                        else {
                            alert("유저 삭제 완료")
                            fetchUserList()
                            return
                        }
                    }
                })
        }
    }

    function editAuthList(e, id, idx) {
        setExistingAuthList((prevList) =>
            prevList.map((item) => {
                if (item.id === parseInt(id)) {
                    item.permissions[idx] = e.target.value
                }
                return item
            })
        )
        console.log(existingAuthList)
    }

    function addPermtoAuthList(e) {
        e.preventDefault()
        if (existingAuthList.find((auth) => auth.id === parseInt(managingAuthList))?.permissions.length === 9) alert("이미 9개의 권한이 추가되었습니다.")
        else {
            setExistingAuthList((prevList) =>
                prevList.map((item) => {
                    if (item.id === parseInt(managingAuthList)) {
                        item.permissions.push("1")
                    }
                    return item
                })
            )
        }
    }

    function delPermfromAuthList(e, id, idx) {
        e.preventDefault()
        setExistingAuthList((prevList) =>
            prevList.map((item) => {
                if (item.id === parseInt(id)) {
                    item.permissions.splice(idx, 1)
                }
                return item
            }))
    }

    async function submitAuthList(e) {
        e.preventDefault()
        if (!accessToken) {
            alert("API 토큰 없음")
            return
        }
        else {
            const formData = new FormData()
            formData.append("id", managingAuthList)
            formData.append("permissions", JSON.stringify(existingAuthList.find((auth) => auth.id === parseInt(managingAuthList))?.permissions))

            fetch('https://api.cendori.entr.netfrc.com/perms/authlist', {
                method: "PATCH",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                body: formData
            }).then((response) => response.json())
                .then((data) => {
                    if (data.error) alert(data.error)
                    else {
                        if (data.authorized === false) {
                            alert("접근 권한 없음")
                            return
                        }
                        else {
                            alert("권한 리스트 수정 완료")
                            fetchAuthList()
                            return
                        }
                    }
                })
        }
    }

    async function deleteAuthList(e) {
        e.preventDefault()
        if (!accessToken) {
            alert("API 토큰 없음")
            return
        }
        else {
            if (delAuthList[0] === delAuthList[1]) {
                alert("대체 권한은 삭제 권한과 동일할 수 없습니다.")
                return
            }
            const formData = new FormData()
            formData.append("id", delAuthList[0])
            formData.append("replace", delAuthList[1])

            fetch('https://api.cendori.entr.netfrc.com/perms/authlist', {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                body: formData
            }).then((response) => response.json())
                .then((data) => {
                    if (data.error) alert(data.error)
                    else {
                        if (data.authorized === false) {
                            alert("접근 권한 없음")
                            return
                        }
                        else {
                            alert("권한 리스트 삭제 완료")
                            fetchAuthList()
                            return
                        }
                    }
                })
        }
    }

    function addPermtoNewAuthList(e) {
        e.preventDefault();
        if (newAuthList.permissions.length === 9) {
            alert("이미 9개의 권한이 추가되었습니다.");
        } else {
            setNewAuthList((prevList) => {
                return {
                    ...prevList,
                    permissions: [...prevList.permissions, "1"]
                };
            });
        }
    }

    function editNewAuthList(e, idx) {
        e.preventDefault()
        setNewAuthList((prevList) => {
            const updatedPermissions = [...prevList.permissions]
            updatedPermissions[idx] = e.target.value

            return {
                ...prevList,
                permissions: updatedPermissions,
            }
        })
    }

    function delPermNewAuthList(e, idx) {
        e.preventDefault()
        setNewAuthList((prevList) => {
            const updatedPermissions = [...prevList.permissions]
            updatedPermissions.splice(idx, 1)

            return {
                ...prevList,
                permissions: updatedPermissions,
            }
        })
    }

    async function submitNewAuthList(e) {
        e.preventDefault()
        if (!accessToken) {
            alert("API 토큰 없음")
            return
        }
        else {
            const formData = new FormData()
            formData.append("name", newAuthList.name)
            formData.append("permissions", JSON.stringify(newAuthList.permissions))

            fetch('https://api.cendori.entr.netfrc.com/perms/authlist', {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
                body: formData
            }).then((response) => response.json())
                .then((data) => {
                    if (data.error) alert(data.error)
                    else {
                        if (data.authorized === false) {
                            alert("접근 권한 없음")
                            return
                        }
                        else {
                            alert("권한 리스트 추가 완료")
                            fetchAuthList()
                            return
                        }
                    }
                })
        }
    }

    async function fetchUserLogs(e) {
        e.preventDefault()
        if (!accessToken) {
            alert("API 토큰 없음")
            return
        }
        else {
            fetch(`https://api.cendori.entr.netfrc.com/logs/view?logtoid=${selectLogUser}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((response) => response.json())
                .then((data) => {
                    if (data.error) alert(data.error)
                    else {
                        if (data.authorized === false) {
                            alert("접근 권한 없음")
                            return
                        }
                        else {
                            setUserLogs(data.logs)
                            console.log(data.logs)
                        }
                    }
                })
        }
    }

    async function changeUserAuth(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("logtoid", changingUser)
        formData.append("authlist", changingAuth)

        fetch('https://api.cendori.entr.netfrc.com/user/manage', {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            body: formData
        }).then((response) => response.json())
            .then((data) => {
                if (data.error) alert(data.error)
                else {
                    if (data.authorized === false) {
                        alert("접근 권한 없음")
                        return
                    }
                    else {
                        alert("유저 권한 변경 완료")
                        fetchUserList()
                        return
                    }
                }
            })
    }

    async function sendAddUserInfo(e) {
        const formData = new FormData()
        formData.append("name", addUserInfo.name)
        formData.append("company", addUserInfo.company)
        formData.append("team", addUserInfo.department)
        formData.append("email", addUserInfo.email)
        formData.append("password", addUserInfo.password)
        formData.append("authlist", addUserInfo.authlist)

        e.preventDefault()
        fetch('https://api.cendori.entr.netfrc.com/user/add', {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            body: formData
        }).then((response) => response.json())
            .then((data) => {
                if (data.error) alert(data.error)
                else {
                    if (data.authorized === false) {
                        alert("접근 권한 없음")
                        return
                    }
                    else {
                        alert("유저 추가 완료")
                        fetchUserList()
                        return
                    }
                }
            })
    }

    return (
        <div>
            {
                !isLoading ? (
                    isAuthenticated ? (
                        <div className="cendoriwebbody" style={{ minHeight: "100vh" }}>
                            <WeblinkHeader />
                            <div className="cendorimanagefirmcol w-row">
                                <div class="cendorimanagefirmcol1 w-col w-col-3">
                                    { /* LeftSide Current Buttons */}
                                    {
                                        visb[0] ? <div className="cendorifirmwaremanagebtn current">유저 추가</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([true, false, false, false, false, false, false])}>유저 추가</div>
                                    }
                                    {
                                        visb[1] ? <div className="cendorifirmwaremanagebtn current">유저 권한 관리</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, true, false, false, false, false, false])}>유저 권한 관리</div>
                                    }
                                    {
                                        visb[2] ? <div className="cendorifirmwaremanagebtn current">유저 삭제</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, true, false, false, false, false])}>유저 삭제</div>
                                    }
                                    {
                                        visb[3] ? <div className="cendorifirmwaremanagebtn current">권한 리스트 추가</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, true, false, false, false])}>권한 리스트 추가</div>
                                    }
                                    {
                                        visb[4] ? <div className="cendorifirmwaremanagebtn current">권한 리스트 관리</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, false, true, false, false])}>권한 리스트 관리</div>
                                    }
                                    {
                                        visb[5] ? <div className="cendorifirmwaremanagebtn current">권한 리스트 삭제</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, false, false, true, false])}>권한 리스트 삭제</div>
                                    }
                                    {
                                        visb[6] ? <div className="cendorifirmwaremanagebtn current">활동 로그</div> : <div className="cendorifirmwaremanagebtn" onClick={() => setVisb([false, false, false, false, false, false, true])}>활동 로그</div>
                                    }
                                </div>
                                <div class="cendorimanagefirmcol2 w-col w-col-9">
                                    {
                                        visb[0] ?
                                            <div className="cendorifirmwaremanagepane useradd">
                                                <form onSubmit={e => sendAddUserInfo(e)} id="user-add-form" name="user-add-form" className="form">
                                                    <label for="name" className="field-label">실명</label>
                                                    <input className="text-field w-input" maxlength="256" name="name" placeholder="" required="" type="text" id="name" value={addUserInfo.name} onChange={e => setAddUserInfo({ name: e.target.value, company: addUserInfo.company, department: addUserInfo.department, email: addUserInfo.email, password: addUserInfo.password, authlist: addUserInfo.authlist })} />
                                                    <label for="company" className="field-label">소속사</label>
                                                    <input className="text-field w-input" maxlength="256" name="company" placeholder="" type="text" id="company" required="" value={addUserInfo.company} onChange={e => setAddUserInfo({ name: addUserInfo.name, company: e.target.value, department: addUserInfo.department, email: addUserInfo.email, password: addUserInfo.password, authlist: addUserInfo.authlist })} />
                                                    <label for="department" className="field-label">소속 부서</label>
                                                    <input className="text-field w-input" maxlength="256" name="department" placeholder="" type="text" id="department" required="" value={addUserInfo.department} onChange={e => setAddUserInfo({ name: addUserInfo.name, company: addUserInfo.company, department: e.target.value, email: addUserInfo.email, password: addUserInfo.password, authlist: addUserInfo.authlist })} />
                                                    <label for="email" className="field-label">이메일 </label>
                                                    <input className="text-field w-input" maxlength="256" name="email" placeholder="" type="email" id="email" required="" value={addUserInfo.email} onChange={e => setAddUserInfo({ name: addUserInfo.name, company: addUserInfo.company, department: addUserInfo.department, email: e.target.value, password: addUserInfo.password, authlist: addUserInfo.authlist })} />
                                                    <label for="password" className="field-label">비밀번호</label>
                                                    <input className="text-field w-input" maxlength="256" name="password" placeholder="" type="password" id="Device-Profile-ID-3" required="" value={addUserInfo.password} onChange={e => setAddUserInfo({ name: addUserInfo.name, company: addUserInfo.company, department: addUserInfo.department, email: addUserInfo.email, password: e.target.value, authlist: addUserInfo.authlist })} />
                                                    <label for="authlist" className="field-label">권한 리스트 </label>
                                                    <select id="authlist" name="authlist" className="select-field w-select" required="" value={addUserInfo.authlist} onChange={e => setAddUserInfo({ name: addUserInfo.name, company: addUserInfo.company, department: addUserInfo.department, email: addUserInfo.email, password: addUserInfo.password, authlist: e.target.value })}>
                                                        {
                                                            existingAuthList ? (
                                                                existingAuthList.map((option) => (
                                                                    <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                                ))
                                                            ) : (
                                                                <option></option>
                                                            )
                                                        }
                                                    </select>
                                                    <input type="submit" className="submit-button w-button" value="유저 추가" />
                                                </form>
                                            </div> : <div></div>
                                    }
                                    {
                                        visb[1] ?
                                            <div className="cendorifirmwaremanagepane useradd">
                                                <form onSubmit={e => changeUserAuth(e)} id="user-auth-manage" name="user-auth-manage" className="form">
                                                    <label for="changingUser" className="field-label-2">해당 유저 </label>
                                                    <select id="changingUser" name="changingUser" value={changingUser} onChange={e => setChangingUser(e.target.value)} required="" className="select-field nopadding w-select">
                                                        {
                                                            existingUserList ? (
                                                                existingUserList.map((option) => (
                                                                    <option value={option.logtoid} key={option.logtoid}>{option.name + " (" + option.logtoid + ")"}</option>
                                                                ))
                                                            ) : (
                                                                <option></option>
                                                            )
                                                        }
                                                    </select>
                                                    <label for="changingAuth" className="field-label-2">변경될 권한 리스트 </label>
                                                    <select id="changingAuth" name="changingAuth" value={changingAuth} onChange={e => setChangingAuth(e.target.value)} required="" className="select-field w-select">
                                                        {
                                                            existingAuthList ? (
                                                                existingAuthList.map((option) => (
                                                                    <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                                ))
                                                            ) : (
                                                                <option></option>
                                                            )
                                                        }
                                                    </select>
                                                    <input type="submit" className="submit-button w-button" value="유저 권한 변경" />
                                                </form>
                                            </div> : <div></div>
                                    }
                                    {
                                        visb[2] ?
                                            <div className="cendorifirmwaremanagepane useradd">
                                                <form id="user-del-form" name="user-del-form" className="form" onSubmit={deleteUser}>
                                                    <label for="ProfileID-4" className="field-label-2">해당 유저</label>
                                                    <select id="existusrid" name="existusrid" data-name="existusrid" required="" value={usrLogtoID} className="select-field w-select" onChange={e => setUsrLogtoID(e.target.value)}>
                                                        {
                                                            existingUserList ? (
                                                                existingUserList.map((option) => (
                                                                    <option value={option.logtoid} key={option.logtoid}>{option.name + " (" + option.logtoid + ")"}</option>
                                                                ))
                                                            ) : (
                                                                <option></option>
                                                            )
                                                        }
                                                    </select>
                                                    <input type="submit" className="submit-button w-button" value="유저 삭제" />
                                                </form>
                                            </div> : <div></div>
                                    }
                                    {
                                        visb[3] ?
                                            <div className="cendorifirmwaremanagepane useradd">
                                                <form onSubmit={e => submitNewAuthList(e)} id="authlist-add-form" name="authlist-add-form" className="form">
                                                    <label for="field-5" className="field-label">권한 리스트명 </label>
                                                    <input className="text-field w-input" maxlength="30" name="authlistname" placeholder="" type="text" id="authlistname" required="" value={newAuthList.name} onChange={e => setNewAuthList({ "name": e.target.value, "permissions": newAuthList.permissions })} />
                                                    <label for="field-5" className="field-label">해당 권한</label>
                                                    {
                                                        newAuthList ? (
                                                            newAuthList.permissions.map((option, index) => (
                                                                <div className="authdropdownflexdiv">
                                                                    <select id={index} name={index} value={newAuthList.permissions[index]} onChange={e => editNewAuthList(e, index)} required="" className="select-field nopadding actuallynone w-select">
                                                                        {
                                                                            existingPermList ? (
                                                                                existingPermList.map((option) => (
                                                                                    <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                                                ))
                                                                            ) : (
                                                                                <option></option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                    <button onClick={e => delPermNewAuthList(e, index)} className="text-block-4" style={{ backgroundColor: "#292929" }}>삭제</button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div></div>
                                                        )
                                                    }
                                                    <button onClick={e => addPermtoNewAuthList(e)} className="submitfilebtn yespadding w-button">권한 추가</button>
                                                    <input type="submit" className="submit-button w-button" value="권한 리스트 추가" />
                                                </form>
                                            </div> : <div></div>
                                    }
                                    {
                                        visb[4] ?
                                            <div className="cendorifirmwaremanagepane useradd">
                                                <form id="authlist-manage-form" name="authlist-manage-form" className="form" onSubmit={e => submitAuthList(e)}>
                                                    <label for="ProfileID-4" className="field-label-2">권한 리스트명</label>
                                                    <select id="ProfileID-2" name="ProfileID-2" data-name="Profile ID 2" value={managingAuthList} onChange={e => setManagingAuthList(e.target.value)} required="" className="select-field manage w-select">
                                                        {
                                                            existingAuthList ? (
                                                                existingAuthList.map((option) => (
                                                                    <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                                ))
                                                            ) : (
                                                                <option></option>
                                                            )
                                                        }
                                                    </select>
                                                    <label for="field-5" className="field-label">해당되는 권한</label>
                                                    {
                                                        existingAuthList ? (
                                                            existingAuthList.find((auth) => auth.id === parseInt(managingAuthList))?.permissions.map((option, index) => {
                                                                return (
                                                                    <div className="authdropdownflexdiv">
                                                                        <select id={index} name={index} required="" value={existingAuthList.find((auth) => auth.id === parseInt(managingAuthList))?.permissions[index]} onChange={e => editAuthList(e, managingAuthList, index)} className="select-field nopadding actuallynone w-select">
                                                                            {
                                                                                existingPermList ? (
                                                                                    existingPermList.map((option) => (
                                                                                        <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                                                    ))
                                                                                ) : (
                                                                                    <option></option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                        <button className="text-block-4" style={{ backgroundColor: "#292929" }} onClick={e => delPermfromAuthList(e, managingAuthList, index)}>삭제</button>
                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            <div></div>
                                                        )
                                                    }
                                                    <button onClick={e => addPermtoAuthList(e)} className="submitfilebtn yespadding w-button">권한 추가</button>
                                                    <input type="submit" className="submit-button w-button" value="권한 리스트 수정" />
                                                </form>
                                            </div> : <div></div>
                                    }
                                    {
                                        visb[5] ?
                                            <div className="cendorifirmwaremanagepane useradd">
                                                <form onSubmit={e => deleteAuthList(e)} id="authlist-del-form" name="authlist-del-form" className="form">
                                                    <label for="ProfileID-4" className="field-label-2">삭제 권한 리스트명</label>
                                                    <select id="delAuthID" name="delAuthID" value={delAuthList[0]} onChange={e => setDelAuthList([e.target.value, delAuthList[1]])} required="" className="select-field manage w-select">
                                                        {
                                                            existingAuthList ? (
                                                                existingAuthList.map((option) => (
                                                                    <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                                ))
                                                            ) : (
                                                                <option></option>
                                                            )
                                                        }
                                                    </select>
                                                    <label for="field-5" className="field-label">대체 권한 리스트</label>
                                                    <select id="replacingAuthID" name="replacingAuthID" value={delAuthList[1]} onChange={e => setDelAuthList([delAuthList[0], e.target.value])} required="" className="select-field w-select">
                                                        {
                                                            existingAuthList ? (
                                                                existingAuthList.map((option) => (
                                                                    <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                                ))
                                                            ) : (
                                                                <option></option>
                                                            )
                                                        }
                                                    </select>
                                                    <input type="submit" className="submit-button w-button" value="권한 리스트 삭제" />
                                                </form>
                                            </div> : <div></div>
                                    }
                                    {
                                        visb[6] ? <div className="cendorifirmwaremanagepane useradd">
                                            <form onSubmit={e => fetchUserLogs(e)} id="log-search-form" name="log-search-form" className="form">
                                                <label for="selectuserlog" className="field-label-2">활동 유저 필터링</label>
                                                <select id="selectuserlog" name="selectuserlog" value={selectLogUser} onChange={e => setSelectLogUser(e.target.value)} required="" className="select-field manage w-select">
                                                    {
                                                        existingUserList ? (
                                                            existingUserList.map((option) => (
                                                                <option value={option.logtoid} key={option.logtoid}>{option.name + " (" + option.logtoid + ")"}</option>
                                                            ))
                                                        ) : (
                                                            <option></option>
                                                        )
                                                    }
                                                </select>
                                                <input type="submit" className="submitfilebtn userfilter w-button" value="로그 확인" />
                                            </form>
                                            <div className="cendorilogdiv main">
                                                <div className="cendoriloglistdiv">
                                                    <div id="w-node-_03782789-a437-f1cd-30e5-79a4e5b209e8-c2738268" className="cendorilogcentraldiv">
                                                        <div>유저</div>
                                                    </div>
                                                    <div id="w-node-_575e99d4-3675-c6ea-bf27-9efa1f024490-c2738268" className="cendorilogcentraldiv">
                                                        <div>유저 활동</div>
                                                    </div>
                                                    <div id="w-node-_27668aac-cf9f-09a8-9ef5-a28d733733f0-c2738268" className="cendorilogcentraldiv">
                                                        <div>아이피 주소</div>
                                                    </div>
                                                    <div id="w-node-_2971d793-2d9d-f5f1-2780-69b52f36ea8f-c2738268" className="cendorilogcentraldiv">
                                                        <div>접근 시간대</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                userLogs ? (
                                                    userLogs.map((option, index) => (
                                                        <div className="cendorilogdiv">
                                                            <div className="cendoriloglistdiv">
                                                                <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10be9-c2738268" className="cendorilogcentraldiv">
                                                                    <div>{option.user}</div>
                                                                </div>
                                                                <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10bec-c2738268" className="cendorilogcentraldiv">
                                                                    <div>{option.api}</div>
                                                                </div>
                                                                <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10bef-c2738268" className="cendorilogcentraldiv">
                                                                    <div>{option.ip}</div>
                                                                </div>
                                                                <div id="w-node-_05fb2af4-015d-279c-6aba-467169e10bf2-c2738268" className="cendorilogcentraldiv">
                                                                    <div>{option.time.replace(".000Z", "")} KST</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div></div>
                                                )
                                            }
                                        </div> : <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        signIn(`${domain}/callback`)
                    )
                ) : (
                    <div></div>
                )
            }
        </div>
    )
}

export default UserManage