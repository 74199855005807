import { useState, useEffect } from 'react'
import { useLogto } from '@logto/react'

function WeblinkHeader() {
    const [vis, setVis] = useState([false, false, false])
    const { signOut } = useLogto()

    return (
        <div className="cendoriwebheader">
            <a href="/" aria-current="page" className="cendoriweblogolink w-inline-block w--current">
                CENDORI
            </a>

            <div className="cendoriweblinkwrapper">
                <div className="cendoriwebdropdown w-dropdown" onClick={() => setVis([!vis[0], vis[1], vis[2]])}>
                    <div className="dropdown-toggle w-dropdown-toggle">
                        <div className="icon w-icon-dropdown-toggle"></div>
                        <div className="text-block">펌웨어</div>
                    </div>
                    {
                        vis[0] ? <nav className="cendoridropdownlist w-dropdown-list w--open">
                            <a href="../firmware/manage" className="cendoridropdownlink first w-dropdown-link">펌웨어 관리</a>
                            <a href="../firmware/stats" className="cendoridropdownlink w-dropdown-link">펌웨어 통계</a>
                        </nav> :
                            <div></div>
                    }
                </div>
                <div className="cendoriwebdropdown w-dropdown" onClick={() => setVis([vis[0], !vis[1], vis[2]])}>
                    <div className="dropdown-toggle w-dropdown-toggle">
                        <div className="icon w-icon-dropdown-toggle"></div>
                        <div className="text-block">유저</div>
                    </div>
                    {
                        vis[1] ? <nav className="cendoridropdownlist w-dropdown-list w--open">
                            <a href="../user/manage" className="cendoridropdownlink first w-dropdown-link">유저 관리</a>
                        </nav> :
                            <div></div>
                    }
                </div>
                <div className="cendoriwebdropdown w-dropdown" onClick={() => setVis([vis[0], vis[1], !vis[2]])}>
                    <div className="dropdown-toggle w-dropdown-toggle">
                        <div className="icon w-icon-dropdown-toggle"></div>
                        <div className="text-block">서버</div>
                    </div>
                    {
                        vis[2] ? <nav className="cendoridropdownlist w-dropdown-list w--open">
                            <a href="../server/domain" className="cendoridropdownlink first w-dropdown-link">도메인 관리</a>
                        </nav> :
                            <div></div>
                    }
                </div>
                <div className="cendoriwebdropdown w-dropdown" onClick={() => signOut('http://cendori.com/')}>
                    로그아웃
                </div>
            </div>
        </div>
    )
}

export default WeblinkHeader; 