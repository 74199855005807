import { useEffect, useState } from "react"
import WeblinkHeader from "../components/WebLinkHeader"
import { useLogto } from '@logto/react';
const { domain } = require('../config/generalConfig.json')

function ServerDomainManage() {
    const { signIn, isAuthenticated, isLoading } = useLogto();

    useEffect(() => {

    }, [isAuthenticated, isLoading])

    return (
        <div>
            {
                !isLoading ? (
                    isAuthenticated ? (
                        <div className="cendoriwebbody" style={{ minHeight: "100vh" }}>
                            <WeblinkHeader />
                            <div className="cendoriservermaindiv">
                                <div className="cendorimaindivlogintext firmwareinfo">서버 도메인 보안 관리 </div>
                                <form id="server-domain-form" name="server-domain-form" class="form">
                                    <label for="Device-Profile-ID-4" class="field-label">어드민 콘솔 도메인</label>
                                    <input class="text-field w-input" maxlength="256" name="name" data-name="Name" placeholder="" type="text" id="name" />
                                    <label for="Device-Profile-ID" class="field-label">펌웨어 엑세스 도메인</label>
                                    <input class="text-field padding w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="" type="text" id="name-2" />
                                    <input type="submit" data-wait="Please wait..." class="submit-button w-button" value="보안 설정" />
                                </form>
                            </div>
                        </div>
                    ) : (
                        signIn(`${domain}/callback`)
                    )
                ) : (
                    <div></div>
                )
            }
        </div>
    )
}

export default ServerDomainManage