import WeblinkHeader from './components/WebLinkHeader'
import { useLogto } from '@logto/react';
import { useEffect, useState } from 'react';
const { domain } = require('./config/generalConfig.json')

function App() {
  const { signIn, getAccessToken, signOut, isAuthenticated, isLoading } = useLogto();
  const [accessToken, setAccessToken] = useState(null)
  const [userFetch, setUserFetch] = useState(null)

  useEffect(() => {
    async function getAPIToken() {
      try {
        if (!isLoading && isAuthenticated && !accessToken) {
          const actk = await getAccessToken('https://api.cendori.entr.netfrc.com/')
          setAccessToken(actk)
        }
        if (accessToken) sendLogInfo("메인페이지")
        console.log(accessToken)
      } catch (e) {
        signOut('http://cendori.com/')
      }
    }

    async function getUserInfo() {
      if (accessToken) {
        fetch('https://api.cendori.entr.netfrc.com/user/info', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }).then((response) => response.json())
          .then((data) => {
            console.log(data)
            setUserFetch(data)
          })
      }
    }

    getAPIToken()
    getUserInfo()
    console.log(accessToken)
  }, [isLoading, accessToken, isAuthenticated, getAccessToken])

  async function sendLogInfo(text) {
    if (accessToken) {
      const formData = new FormData()
      formData.append("api", text)
      fetch('https://api.cendori.entr.netfrc.com/logs/view', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData
      }).then((response) => response.json())
        .then((data) => {
          console.log(data)
        })
    }
  }

  return (
    <div>
      {
        !isLoading ? (
          isAuthenticated ? (
            <div className="cendoriwebbody" style={{ minHeight: "100vh" }}>
              <WeblinkHeader />
              <div className="cendorimainpagediv">
                {/* Website Mainpage Begins */}
                <div className="cendorimaincolumn1 w-col w-col-4">
                  {/* Mainpage Column Left */}
                  <div class="cendorimaindivlogintext">로그인 유저 정보</div>
                  <div class="cendorimaindivloginname">환영합니다, {userFetch?.email} 님.</div>
                  <div class="cendorimaindivloginname">이름: {userFetch?.name}</div>
                  <div class="cendorimaindivloginname">소속사: {userFetch?.company}</div>
                  <div class="cendorimaindivloginname last">부서: {userFetch?.team}</div>
                  <div class="cendorimaindivdivider"></div>
                  <div class="cendorimaindivloginname">권한 리스트:</div>
                  <div class="cendorimaindivloginname rolelist">{userFetch?.authlist}</div>
                </div>
                <div className="cendorimaincolumn2 w-col w-col-8">
                  {/* Mainpage Colum Right */}
                  <div className="cendorimaindivlogintext">서버 정보</div>
                  <div className="cendorimaindivloginname">원본서버 IP: 23.162.104.0 — <span className="working">보안 작동중</span></div>
                  <div className="cendorimaindivloginname">원본서버 상태: <span className="working">정상</span></div>
                  <div className="cendorimaindivloginname">Phylaxis DDoS Proxy: 103.218.172.10 — <span className="notworking">보안 미작동중</span></div>
                </div>
              </div>
            </div>
          ) : (
            signIn(`${domain}/callback`)
          )
        ) : (
          <div class="cendoriwebbody unauthorized" style={{ height: "100vh" }}>
            <div class="cendorimaindivlogintext firmwareinfo unauthorized">로그인 중...</div>
            <div class="cendorisecondarytextdiv">만약 이 화면이 지속된다면 로그아웃 후 재시도 해보세요.</div>
            <div class="cendorisecondarytextdiv logout" onClick={() => signOut('http://cendori.com/')}>로그아웃 →</div>
          </div>
        )
      }
    </div>
  );
}

export default App;